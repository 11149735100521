import { FC } from 'react';
import { dataAttributeProps } from '../../../utils/ComponentUtils';

type BadgeProps = {
  text: string;
  className?: string;
  textClass?: string;
  textSize?: string;
  backgroundClass?: string;
};

const Badge: FC<BadgeProps> = (props) => {
  const { text, className, textClass = 'text-status-tag-4', backgroundClass = 'bg-gray-5', textSize = 'text-dpm-12' } = props;

  return (
    <div
      data-cy="badge"
      {...dataAttributeProps(props)}
      className={`inline-flex flex-row items-center rounded-2xl px-3 py-1 ${className} ${backgroundClass}`}
    >
      <div className={`${textSize} w-full text-center font-medium ${textClass}`}>{text}</div>
    </div>
  );
};

export default Badge;
